<template>
	<v-row>
		<EquipamentoDialog :dialog="dialogProduto" @onClose="dialogProduto = false" />
		<v-snackbar color="primary" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
			<span class="font-weight-bold text-uppercase">{{snackbar.text}}</span>
			<template v-slot:action="{ attrs }">
				<v-btn
					class="font-weight-bold"
					color="blue-grey lighten-3"
					text
					v-bind="attrs"
					@click="snackbar.status = false"
				>Fechar</v-btn>
			</template>
		</v-snackbar>
		<v-dialog v-model="dialog" fullscreen scrollable transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar max-height="60" flat color="primary" dark>
					<v-btn text @click="dialog = !dialog">
						<v-icon class="mr-3">mdi-close</v-icon>
					</v-btn>
					<v-spacer />
					<v-toolbar-title>Filtros</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text @click="dialog = !dialog">Filtrar</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text>
					<v-row class="pa-3">
						<v-col cols="12">
							Estoque
							<v-checkbox
								color="success"
								class="py-0 my-0 mb-n3"
								v-for="(item,i) in 2"
								:key="i"
								:label="`Opção ${item}`"
								:value="item"
							/>
						</v-col>
						<v-col cols="12">
							Categoria
							<v-checkbox
								color="success"
								class="py-0 my-0 mb-n3"
								v-for="(item,i) in 5"
								:key="i"
								:label="`Categoria ${item}`"
								:value="item"
							/>
						</v-col>
						<v-col cols="12">
							Subcategoria
							<v-checkbox
								color="success"
								class="py-0 my-0 mb-n3"
								v-for="(item,i) in 5"
								:key="i"
								:label="`Categoria ${item}`"
								:value="item"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="alerta" persistent max-width="575">
			<v-card tile class="pa-5">
				<div class="text-center pt-5">
					<v-icon class="text-h1" color="blue-grey lighten-2">mdi-help-circle-outline</v-icon>
				</div>
				<v-card-title class="text-h5">
					<span class="mx-auto">Exclusão de produtos</span>
				</v-card-title>
				<v-card-text
					class="text-center text-subtitle-1"
				>Confirmar a exclusão de {{produtos.length}} produto(s) do pedido?</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" class="px-5" large elevation="0" @click="limparPedido()">Confimar</v-btn>
					<v-btn color="red" dark class="px-5" large elevation="0" @click="alerta = false">Cancelar</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalhe.status" scrollable>
			<v-card>
				<v-card-title class="pa-4">
					Detalhes da solicitação
					<v-spacer />
					<v-btn fab color="primary" x-small elevation="0" @click="quantidade++">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pt-8">
					<v-row v-for="(qtd, i) in quantidade" :key="i">
						<v-col cols="auto" v-if="quantidade > 1">
							<v-btn fab color="red" dark x-small elevation="0" @click="quantidade--">
								<v-icon>mdi-minus</v-icon>
							</v-btn>
						</v-col>
						<v-col>
							<v-text-field
								class="mb-n3"
								label="Quantidade"
								dense
								type="number"
								v-model.number="dialogDetalhe.produto.quantidade"
							></v-text-field>
						</v-col>
						<v-col>
							<v-select
								persistent-hint
								dense
								label="Prazo"
								item-text="prazo"
								item-value="prazo"
								:items="dialogDetalhe.produto.prazos"
								v-model="dialogDetalhe.produto.prazoSelecionado"
							/>
						</v-col>
						<template v-for="(valor, i) in dialogDetalhe.produto.prazos">
							<v-col v-if="valor.prazo == dialogDetalhe.produto.prazoSelecionado" :key="i">
								<v-text-field
									class="mb-n3"
									label="Valor"
									dense
									readonly
									:value="valor.valor | formataDinheiro"
								></v-text-field>
							</v-col>
						</template>
						<template v-for="(valor, i) in dialogDetalhe.produto.prazos">
							<v-col v-if="valor.prazo == dialogDetalhe.produto.prazoSelecionado" :key="i">
								<v-text-field
									class="mb-n3"
									label="Valor com desconto"
									dense
									readonly
									:value="valor.valor - valor.desconto | formataDinheiro"
								></v-text-field>
							</v-col>
						</template>
						<template v-for="(valor, i) in dialogDetalhe.produto.prazos">
							<v-col v-if="valor.prazo == dialogDetalhe.produto.prazoSelecionado" :key="i">
								<v-text-field
									class="mb-n3"
									label="Subtotal"
									dense
									readonly
									:value="(valor.valor - valor.desconto) * dialogDetalhe.produto.quantidade | formataDinheiro"
								></v-text-field>
							</v-col>
						</template>
					</v-row>
					<v-row v-for="(valor, i) in dialogDetalhe.produto.prazos" :key="i">
						<template v-if="valor.prazo == dialogDetalhe.produto.prazoSelecionado">
							<v-spacer></v-spacer>
							<v-col class="pt-1 pb-0 mb-n2 text-end">
								<p class="text-caption text-truncate mb-1">Valor</p>
								<v-badge icon="mdi-arrow-down" color="green" overlap>
									<p
										class="text-h6 text-truncate text-decoration-line-through"
									>{{valor.valor | formataDinheiro}}</p>
								</v-badge>
							</v-col>
							<v-col class="pt-1 text-end">
								<span class="text-caption text-truncate">Valor com desconto</span>
								<p class="text-h5 text-truncate">{{valor.valor - valor.desconto | formataDinheiro}}</p>
							</v-col>
							<v-col class="pt-1 text-end">
								<span class="text-caption text-truncate">Subtotal</span>
								<p
									class="text-h5 text-truncate"
								>{{((valor.valor - valor.desconto) * (parseInt(dialogDetalhe.produto.quantidade) || 0))| formataDinheiro}}</p>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn
						elevation="0"
						color="primary"
						@click="adicionarProdutoPedido(dialogDetalhe.produto)"
					>Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" lg="3" class="d-none d-lg-block">
			<v-card dark color="primary">
				<v-card-title>Filtros de busca</v-card-title>
				<v-card-text>
					<v-expansion-panels v-model="panel" flat multiple>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Estoque</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									color="success"
									class="py-0 my-0 mb-n3"
									v-for="(item,i) in 2"
									:key="i"
									:label="`Opção ${item}`"
									:value="item"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Categoria</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									color="success"
									class="py-0 my-0 mb-n3"
									v-for="(item,i) in 5"
									:key="i"
									:label="`Categoria ${item}`"
									:value="item"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel class="primary">
							<v-expansion-panel-header class="text-h6 px-0">Subcategoria</v-expansion-panel-header>
							<v-expansion-panel-content class="ml-n6">
								<v-checkbox
									color="success"
									class="py-0 my-0 mb-n3"
									v-for="(item,i) in 5"
									:key="i"
									:label="`Categoria ${item}`"
									:value="item"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" lg="9">
			<v-card>
				<v-card-title>
					<span class="d-none d-md-block">Aluguel de equipamento</span>
					<v-spacer />
					<v-btn
						:disabled="produtos.length == 0"
						elevation="0"
						class="mx-3"
						color="primary"
						@click="alerta = true"
					>
						<span class="d-none d-md-block">Limpar pedido</span>
						<v-icon class="ml-0 ml-md-3">mdi-close</v-icon>
					</v-btn>

					<v-menu
						v-model="menu"
						:open-on-click="true"
						:close-on-content-click="false"
						right
						offset-y
						tile
					>
						<template v-slot:activator="{ on }">
							<v-badge color="red" overlap :content="produtos.length" :value="produtos.length">
								<v-btn v-on="on" :disabled="produtos.length == 0" elevation="0" color="primary">
									<span class="d-none d-md-block">Ver pedido</span>
									<v-icon class="ml-0 ml-md-3">mdi-cart-outline</v-icon>
								</v-btn>
							</v-badge>
						</template>

						<v-card elevation="0" scrollable outlined>
							<v-card-title>
								Pedido
								<v-spacer />
								<v-btn
									:disabled="produtos.length == 0"
									fab
									elevation="0"
									color="primary"
									x-small
									to="aluguel-equipamento/faturar"
								>
									<v-icon>mdi-cart-outline</v-icon>
								</v-btn>
							</v-card-title>
							<v-divider />
							<v-card-text>
								<v-scroll-x-transition
									leave-absolute
									group
									tag="ul"
									v-if="produtos.length > 0"
									class="px-0"
								>
									<v-list-item v-for="(item, i) in produtos" :key="i">
										<v-list-item-avatar>
											<v-img
												src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YAoseDnrCXdnsRmPR-IoM95CjYwrnAHXRXSLGwjZnfhFFjkPhOhxVWE7aPatohU-AmE&usqp=CAU"
											></v-img>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>Furadeira com impacto 1/2", FIV 1050, 127V VONDER</v-list-item-title>
											<v-list-item-subtitle>Prazo: semanal</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-content>
											<div class="text-right text-h6">
												<span class="d-none d-md-inline">R$ 300,00</span>
												<v-btn
													class="ml-2"
													fab
													elevation="0"
													x-small
													color="red"
													dark
													@click="removerProdutoPedido(item)"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-scroll-x-transition>
								<v-list v-else>
									<v-list-item>
										<v-list-item-content>
											<div class="text-center mb-3">Nenhum produto adicionado ao carrinho</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>
								<v-divider class="mt-5" />
								<v-list-item class="mt-3">
									<v-list-item-content>
										<div class="text-right text-h5">Total: R$ 300,00</div>
									</v-list-item-content>
								</v-list-item>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									v-if="produtos.length > 0"
									elevation="0"
									color="primary"
									block
									tile
									to="aluguel-equipamento/faturar"
								>
									Ver pedido
									<v-icon class="ml-3">mdi-cart-outline</v-icon>
								</v-btn>
								<v-btn v-else elevation="0" color="primary" block tile @click="menu = !menu">
									Fechar
									<v-icon class="ml-3">mdi-close</v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" class="d-flex mb-n7">
							<v-text-field
								class="d-none d-lg-block"
								label="Buscar equipamento"
								clearable
								outlined
								dense
								v-model="busca.busca"
								@keydown.enter="listar(0)"
								append-icon="mdi-magnify"
								@click:append="listar(0)"
							/>
							<v-text-field
								class="d-inline-flex d-lg-none"
								label="Buscar equipamento"
								clearable
								outlined
								dense
								v-model="busca.busca"
								@keydown.enter="listar(0)"
								append-icon="mdi-magnify"
								@click:append="listar(0)"
								append-outer-icon="mdi-menu"
								@click:append-outer="dialog = !dialog"
							/>
						</v-col>
						<template v-if="buscado">
							<v-col class="text-h6">Resultados para "{{buscado}}"</v-col>
							<v-spacer />
							<v-col class="text-right">10 produtos</v-col>
						</template>
						<template v-else>
							<v-col class="text-h6 text-truncate">Produtos em destaque</v-col>
							<v-spacer />
							<v-col class="text-right">5 produtos</v-col>
						</template>
					</v-row>
					<v-row>
						<v-col v-for="(item, i) in dados.lista" :key="i" cols="12" sm="6" md="4">
							<v-card
								style="border-left: 5px solid #CCC !important;"
								color="grey lighten-3"
								tile
								elevation="0"
							>
								<v-img width="100%" :src="item.imagem" />
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-card-title v-on="on" class="d-block text-h6 text-truncate pt-0 px-2">{{item.produto}}</v-card-title>
									</template>
									<span>{{item.produto}}</span>
								</v-tooltip>
								<v-card-text class="px-2 pb-0">
									<!-- <v-select
                                        class="mb-n7"
                                        persistent-hint
                                        dense
                                        label="Prazo"
                                        item-text="prazo"
                                        item-value="prazo"
                                        :items="item.prazos"
                                        v-model="item.prazoSelecionado"
									/>-->
									<v-row v-for="(valor, i) in item.prazos" :key="i">
										<template v-if="valor.prazo == item.prazoSelecionado">
											<v-col cols="6" class="text-caption">Estoque: {{item.estoque}} un</v-col>
											<v-col cols="6" class="text-caption text-right">Reserva: {{item.reserva}} un</v-col>
											<!-- <v-col class="pt-1 pb-0 mb-n2">
                                                <v-badge
                                                    icon="mdi-arrow-down"
                                                    color="green"
                                                    overlap
                                                >
                                                    <span
                                                        class="text-h6 text-truncate text-decoration-line-through"
                                                    >{{valor.valor | formataDinheiro}}</span>
                                                </v-badge>
                                            </v-col>
                                            <v-spacer />
                                            <v-col class="pt-1">
                                                <span
                                                    class="text-h5 text-truncate"
                                                >{{valor.valor - valor.desconto | formataDinheiro}}</span>
											</v-col>-->
										</template>
									</v-row>
								</v-card-text>
								<v-card-actions>
									<v-spacer />
									<v-btn fab color="primary" x-small elevation="0" @click="dialogProduto = true">
										<v-icon>mdi-eye-plus</v-icon>
									</v-btn>
									<v-btn
										fab
										elevation="0"
										x-small
										color="primary"
										@click="dialogDetalhe.status = true, dialogDetalhe.produto = item"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
//import axios from 'axios'

import EquipamentoDialog from "./EquipamentoDialog";

export default {
	name: "AluguelEquipamento",
	components: { EquipamentoDialog },
	data: () => ({
		snackbar: { status: false, timeout: 1500 },
		alerta: false,
		quantidade: 1,
		menu: false,
		dialog: false,
		dialogProduto: false,
		dialogDetalhe: { status: false, produto: {} },
		carregando: false,
		dados: {
			lista: [
				{
					produto:
						"Furadeira com impacto 1/2', FIV 1050, 127V VONDER",
					imagem: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YAoseDnrCXdnsRmPR-IoM95CjYwrnAHXRXSLGwjZnfhFFjkPhOhxVWE7aPatohU-AmE&usqp=CAU",
					prazos: [
						{ prazo: "Diária", valor: 100, desconto: 10 },
						{ prazo: "Semanal", valor: 150, desconto: 15 },
						{ prazo: "Quinzenal", valor: 200, desconto: 20 },
						{ prazo: "Mensal", valor: 300, desconto: 30 },
					],
					estoque: 5,
					reserva: 3,
					prazoSelecionado: "Diária",
					quantidade: 1,
				},
				{
					produto:
						"Furadeira com impacto 1/2', FIV 1050, 127V VONDER",
					imagem: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YAoseDnrCXdnsRmPR-IoM95CjYwrnAHXRXSLGwjZnfhFFjkPhOhxVWE7aPatohU-AmE&usqp=CAU",
					prazos: [
						{ prazo: "Diária", valor: 100, desconto: 10 },
						{ prazo: "Semanal", valor: 150, desconto: 15 },
						{ prazo: "Quinzenal", valor: 200, desconto: 20 },
						{ prazo: "Mensal", valor: 300, desconto: 30 },
					],
					estoque: 5,
					reserva: 3,
					prazoSelecionado: "Diária",
					quantidade: 1,
				},
				{
					produto:
						"Furadeira com impacto 1/2', FIV 1050, 127V VONDER",
					imagem: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YAoseDnrCXdnsRmPR-IoM95CjYwrnAHXRXSLGwjZnfhFFjkPhOhxVWE7aPatohU-AmE&usqp=CAU",
					prazos: [
						{ prazo: "Diária", valor: 100, desconto: 10 },
						{ prazo: "Semanal", valor: 150, desconto: 15 },
						{ prazo: "Quinzenal", valor: 200, desconto: 20 },
						{ prazo: "Mensal", valor: 300, desconto: 30 },
					],
					estoque: 5,
					reserva: 3,
					prazoSelecionado: "Diária",
					quantidade: 1,
				},
			],
		},
		busca: {},
		buscado: "",
		panel: [0, 1, 2, 3, 4],
		pontoRetirada: "",
		pontoRetiradaGeral: true,
		produtos: [],
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit"]),
	},
	methods: {
		listar(pg) {
			console.log(pg);
			this.buscado = this.busca.busca;
			/* this.carregando = true
            return axios.post(`${this.backendUrl}produto/listar`, {
                limit    : this.pgLimit,
                offset   : this.pgLimit * pg,
                busca    : this.busca.busca || null,
            }).then( (res) => {
                this.dados = res.data
                this.carregando = false
            }).catch(() => {
                this.dados = {total : 0}
                this.carregando = false
            }) */
		},
		limparPedido() {
			this.produtos = [];
			localStorage.removeItem("aluguel");
			this.alerta = false;
		},
		adicionarProdutoPedido(produto) {
			this.produtos.push(produto);
			localStorage.setItem("aluguel", JSON.stringify(this.produtos));
			this.snackbar.text = "Produto adicionado ao pedido";
			this.snackbar.status = true;
			this.dialogDetalhe.status = false;
		},
		removerProdutoPedido(produto) {
			this.produtos.splice(this.produtos.indexOf(produto), 1);
			localStorage.setItem("aluguel", JSON.stringify(this.produtos));
			this.snackbar.text = "Produto removido do pedido";
			this.snackbar.status = true;
		},
	},
	created() {
		const json = localStorage.getItem("aluguel");
		const produtos = JSON.parse(json);

		if (produtos) {
			this.produtos = produtos;
		}
	},
};
</script>

<style scoped>
</style>