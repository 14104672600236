<template>
    <v-dialog v-model="dialog" fullscreen scrollable>
        <v-card elevation="0">
            <v-card-title class="text-h5 font-weight-bold">
                <v-row align="center">
                    <v-col class="text-truncate primary--text">Cimento 50kg - Todas as obras</v-col>
                    <v-col cols="auto" class="text-end">
                        <v-btn color="primary" text fab @click="$emit('onClose', dialog)">
                            <v-icon size="36">mdi-close-thick</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="pt-5">
                <v-row>
                    <v-col cols="12" md="8" order="2" order-md="1">
                        <v-row>
                            <v-col cols="12">
                                <v-carousel hide-delimiters>
                                    <v-carousel-item
                                        v-for="(item,i) in items"
                                        :key="i"
                                        :src="item.src"
                                    ></v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col cols="12">
                                <div class="text-subtitle-1 font-weight-bold mb-3">Descrição</div>
                                <v-divider class="mb-4" />
                                <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit officiis ex, nesciunt suscipit soluta recusandae qui! Fuga illo velit, tempore nesciunt, quisquam ut pariatur unde delectus error maiores repellat. Officia.</div>
                                <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit officiis ex, nesciunt suscipit soluta recusandae qui! Fuga illo velit, tempore nesciunt, quisquam ut pariatur unde delectus error maiores repellat. Officia.</div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4" order="1" order-md="2">
                        <v-row>
                            <v-col cols="12" class="text-h5">
                                Detalhes
                                <v-divider />
                            </v-col>
                            <v-col>
                                <div class="py-2 text-h6">
                                    <v-icon class="mr-1">mdi-dolly</v-icon>Estoque
                                </div>
                                <div class="py-2 text-h6">
                                    <v-icon class="mr-1">mdi-package-variant-closed</v-icon>Reserva
                                </div>
                            </v-col>
                            <v-col cols="auto" class="text-right">
                                <div class="py-2 text-h6">15 unidades</div>
                                <div class="py-2 text-h6">5 unidades</div>
                            </v-col>
                            <v-col cols="12" class="text-h5">
                                Venda
                                <v-divider />
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <span class="float-left text-h6 mt-3">
                                    <v-icon class="mr-1">mdi-cash-multiple</v-icon>Valor
                                </span>
                                <v-badge icon="mdi-arrow-down" color="green" overlap>
                                    <div
                                        class="text-decoration-line-through text-subtitle-1"
                                    >R$ 1500,00</div>
                                </v-badge>
                                <div class="font-weight-bold text-h6 mt-n2">R$ 1350,00</div>
                            </v-col>
                            <v-col cols="12" class="text-h5">
                                Aluguel de equipamento
                                <v-divider />
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <span class="float-left text-h6 mt-3">
                                    <v-icon class="mr-1">mdi-cash-multiple</v-icon>Diária
                                </span>
                                <v-badge icon="mdi-arrow-down" color="green" overlap>
                                    <div
                                        class="text-decoration-line-through text-subtitle-1"
                                    >R$ 100,00</div>
                                </v-badge>
                                <div class="font-weight-bold text-h6 mt-n2">R$ 90,00</div>
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <span class="float-left text-h6 mt-3">
                                    <v-icon class="mr-1">mdi-cash-multiple</v-icon>Mensal
                                </span>
                                <v-badge icon="mdi-arrow-down" color="green" overlap>
                                    <div
                                        class="text-decoration-line-through text-subtitle-1"
                                    >R$ 300,00</div>
                                </v-badge>
                                <div class="font-weight-bold text-h6 mt-n2">R$ 270,00</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EquipamentoDialog",
    props: ["dialog"],
    data: () => ({
        produtos: [],
        dados: {},
        items: [
            {
                src: "https://www.votorantimcimentos.com.br/wp-content/themes/vcimentos/assets/img/thumbs-videos-cimentos/votoran.jpg",
            },
            {
                src: "https://www.votorantimcimentos.com.br/wp-content/themes/vcimentos/assets/img/thumbs-videos-cimentos/votoran.jpg",
            },
        ],
    }),
    methods: {
        get() {
            /*  this.carregando = true
            return axios.post(`${this.backendUrl}produto/get`, {
                idproduto: parseInt(this.idproduto)
            }).then( (res) => {
                this.dados = res.data
                this.carregando = false
            }).catch(() => {
                this.carregando = false
            }) */
        },
    },
};
</script>